<template>
  <div class="bg-green pt-5 pb-3">
    <footer class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-lg-4">
              <div>
                <h5 class="text-white text-capitalize">Durban</h5>
                <p class="pb-4">
                  <a class="text-white" href="mailto:durban@greentogold.co.za"
                    ><i class="fas fa-envelope pe-3"></i>
                    durban@greentogold.co.za</a
                  >
                </p>
              </div>
              <div>
                <h5 class="text-white text-capitalize">Johannesburg</h5>
                <p class="pb-4">
                  <a class="text-white" href="mailto:joburg@greentogold.co.za"
                    ><i class="fas fa-envelope pe-3"></i>
                    joburg@greentogold.co.za</a
                  >
                </p>
              </div>
              <div>
                <h5 class="text-white text-capitalize">Cape Town</h5>
                <p class="pb-4">
                  <a class="text-white" href="mailto:capetown@greentogold.co.za"
                    ><i class="fas fa-envelope pe-3"></i>
                    capetown@greentogold.co.za</a
                  >
                </p>
              </div>

              <div>
                <p>
                  <a class="text-white" href="https://wa.me/0843235100"
                    ><i class="fab fa-whatsapp pe-3"></i> 084 323 5100</a
                  >
                </p>
              </div>
              <div class="">
                <p class="pb-3">
                  <a class="text-white" href="tel:+27843235100"
                    ><i class="fas fa-phone-alt pe-3"></i> 084 323 5100</a
                  >
                </p>
              </div>
              <div class="">
                <h5 class="pb-4">
                  <a
                    class="text-white pe-3"
                    href="https://www.facebook.com/greentogoldstews"
                    ><i class="fab fa-facebook-square"></i></a
                  ><a
                    class="text-white pe-3"
                    href="https://www.instagram.com/greentogoldstews"
                    ><i class="fab fa-instagram"></i></a
                  ><a
                    class="text-white"
                    href="https://za.pinterest.com/greentogoldstews/"
                    ><i class="fab fa-pinterest-square"></i
                  ></a>
                </h5>
              </div>
            </div>
            <hr class="bg-light text-white mobile-hr" />
            <div class="col-lg-3">
              <p class="text-white">
                If you have any queries, drop me a mail, a WhatsApp or let’s go
                grab a coffee!
              </p>

              <p class="text-white">Love your land based Chief Stews</p>
            </div>
            <hr class="bg-light text-white mobile-hr" />
            <div class="col-lg-4 offset-lg-1">
              <h5 class="text-white pb-4">
                <small>
                  Download our FREE Get Started Guide - Green to Gold's ultimate
                  guide to becoming a superyacht stewardess</small
                >
              </h5>
              <form class="w-100">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label text-white"
                    >Email address</label
                  >
                  <input
                    type="email"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    v-model="email"
                  />
                  <div id="emailHelp" class="form-text">
                    <p>We'll never share your email with anyone else.</p>
                  </div>
                </div>
                <div @click="getInContact()">
                  <button
                    v-if="email == ''"
                    class="btn btn-main btn-lg mb-4 w-100"
                    disabled
                  >
                    <p class="p">Download</p>
                  </button>
                  <a
                    v-if="email != ''"
                    href="http://greentogold.co.za//Green to Gold Ebook MAY_compressed.pdf"
                    target="_blank"
                    class="btn btn-main btn-lg mb-4"
                  >
                    <p class="p">Download</p>
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-12"></div>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
export default {
  name: "Footer",
  data() {
    return {
      loading: false,
      title: "Send",
      error: "",
      email: "",
    };
  },
  methods: {
    getInContact() {
      if (this.email === "") {
        this.error = "Please fill in your email address!";
        setTimeout(() => (this.error = ""), 5000);
      } else {
        axios
          .post(
            "/phpmailer/src/getInContactg2gdownload.php",

            qs.stringify({
              email: this.email,
            })
          )
          .then((response) => {
            this.loading = !false;
            this.title = "Sent :)";
            setTimeout(() => (this.title = "Send"), 5000);
            setTimeout(() => {
              this.loading = !true;
            }, 2000);
            this.email = "";
            console.log(response);
          })
          .catch((error) => {
            this.title = "Something went wrong";
            console.log(error);
          });
      }
    },
  },
};
</script>
