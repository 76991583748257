<template>
  <div class="contact">
    <div class="contact-hero">
      <div
        class="container xlrg-pt d-flex flex-column align-items-center align-items-md-start"
      >
        <h1 class="display-1 text-white text-shadow">
          Contact Us
        </h1>
        <div class="col-lg-6 pt-5">
          <div class="pt-5 w-100">
            <router-link
              class="btn btn-main btn-lg px-4 me-sm-3 d-flex align-items-center"
              to="/packages"
            >
              <h5 class="pt-2">View Packages</h5>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="container col-xl-10 col-xxl-8 px-4 py-5">
      <div class="row align-items-center g-5 py-5">
        <div class="col-lg-7 text-center text-lg-start">
          <h2 class="text-light-green">Get In Touch</h2>
          <p class="">
            We look forward to hearing from you. Green to Gold is here to help
            you achieve your goal of becoming a crew member on a superyacht.
          </p>
        </div>
        <div class="col-12 mx-auto col-lg-5">
          <form class="p-5 border rounded-3 bg-light-blue">
            <div class="form-floating mb-3">
              <input
                type="text"
                class="form-control"
                id="floatingInput"
                placeholder="Your Name"
                v-model="name"
              />
              <label for="floatingInput">Name</label>
            </div>
            <div class="form-floating mb-3">
              <input
                type="tel"
                class="form-control"
                id="floatingPhone"
                placeholder="Phone"
                v-model="number"
              />
              <label for="floatingPhone">Phone</label>
            </div>
            <div class="form-floating mb-3">
              <input
                type="email"
                class="form-control"
                id="floatingEmail"
                placeholder="Email"
                v-model="email"
              />
              <label for="floatingEmail">Email</label>
            </div>
            <div class="mb-3">
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                placeholder="Type your message here"
                style="font-size:1.7rem"
                v-model="message"
              ></textarea>
            </div>

            <button
              class="w-100 btn btn-lg btn-main mx-auto"
              type="button"
              @click="getInContact()"
            >
              <div
                v-if="loading"
                class="spinner-border spinner-border-md text-green"
              ></div>
              <span v-if="loading"> <h5>Sending</h5></span>
              <span v-else
                ><h5>{{ title }}</h5></span
              >
            </button>
            <hr class="my-4" />
            <p class="text-white">
              *Please note that we respond within 24 hours.
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
export default {
  name: "Footer",
  data() {
    return {
      loading: false,
      title: "Send",
      error: "",
      name: "",
      number: "",
      email: "",
      message: "",
    };
  },
  methods: {
    getInContact() {
      if (this.name === "" || this.email === "") {
        this.error = "Please fill out all fields!";
        setTimeout(() => (this.error = ""), 5000);
      } else {
        axios
          .post(
            "/phpmailer/src/getInContactg2g.php",

            qs.stringify({
              name: this.name,
              email: this.email,
              number: this.number,
              message: this.message,
            })
          )
          .then((response) => {
            this.loading = !false;
            this.title = "Sent :)";
            setTimeout(() => (this.title = "Send"), 5000);
            setTimeout(() => {
              this.loading = !true;
            }, 2000);
            this.name = "";
            this.email = "";
            this.number = "";
            this.message = "";
            console.log(response);
          })
          .catch((error) => {
            this.title = "Something went wrong";
            console.log(error);
          });
      }
    },
  },
};
</script>
