<template>
  <div id="navbar-main">
    <nav class="navbar navbar-expand-xl fixed-top desktop">
      <div class="container">
        <router-link class="navbar-brand" to="/"
          ><img src="../assets/logo.png" class="img-fluid" alt=""
        /></router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-expanded="false"
        >
          <i class="fas fa-bars fa-2x text-light-green"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <router-link class="nav-link" aria-current="page" to="/"
                >Home</router-link
              >
            </li>
            <li class="nav-item">
              <router-link class="nav-link" aria-current="page" to="/about"
                >About</router-link
              >
            </li>
            <li class="nav-item">
              <router-link class="nav-link" aria-current="page" to="/why-g2g"
                >Why Green to Gold</router-link
              >
            </li>
            <li class="nav-item">
              <router-link class="nav-link" aria-current="page" to="/packages"
                >Packages</router-link
              >
            </li>
            <li class="nav-item">
              <router-link class="nav-link" aria-current="page" to="/courses"
                >Course Content
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" aria-current="page" to="/contact"
                >Contact</router-link
              >
            </li>

            <li class="nav-item">
              <button
                type="button"
                class="btn btn-main btn-lg"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Sign Up
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- mobile nav -->
    <div class="mobile fixed-top">
      <div class="container">
        <div class="col-6">
          <router-link class="navbar-brand" to="/"
            ><img src="../assets/logo.png" class="img-fluid" alt=""
          /></router-link>
        </div>
        <div class="col-6">
          <Slide right :closeOnNavigation="true">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <router-link class="nav-link" aria-current="page" to="/"
                  >Home</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" aria-current="page" to="/about"
                  >About</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" aria-current="page" to="/why-g2g"
                  >Why Green to Gold</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" aria-current="page" to="/packages"
                  >Packages</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" aria-current="page" to="/courses"
                  >Course Content
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" aria-current="page" to="/contact"
                  >Contact</router-link
                >
              </li>
              <li class="nav-item">
                <button
                  type="button"
                  class="btn btn-main-mobile btn-lg"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Sign Up
                </button>
              </li>
            </ul>
          </Slide>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
$(document).on("scroll", function() {
  if ($(document).scrollTop() > 5) {
    $("#navbar-main").addClass("shrink");
  } else {
    $("#navbar-main").removeClass("shrink");
  }
});
$(".nav-link").on("click", function() {
  $("#navbarSupportedContent").removeClass("show");
});
import { Slide } from "vue-burger-menu";
export default {
  components: {
    Slide, // Register your component
  },
};
</script>
