<template>
  <div>
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-light-blue">
          <div class="modal-header">
            <h5 class="modal-title text-white" id="exampleModalLabel">
              Select Your Packages
            </h5>
            <button
              type="button"
              class="btn-close  text-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form class="p-5 rounded-3">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="floatingInput"
                  placeholder="Your Name"
                  v-model="name"
                />
                <label for="floatingInput">Name</label>
              </div>
              <div class="form-floating mb-3">
                <input
                  type="tel"
                  class="form-control"
                  id="floatingPhone"
                  placeholder="Phone"
                  v-model="number"
                />
                <label for="floatingPhone">Phone</label>
              </div>
              <div class="form-floating mb-3">
                <input
                  type="email"
                  class="form-control"
                  id="floatingEmail"
                  placeholder="Email"
                  v-model="email"
                />
                <label for="floatingEmail">Email</label>
              </div>
              <div class="mb-3 ps-5">
                <div class="form-check py-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox1"
                    value="Course 1"
                    v-model="options"
                  />
                  <label
                    class="form-check-label text-white"
                    for="inlineCheckbox1"
                  >
                    G2G Stewardess Course</label
                  >
                </div>
                <div class="form-check py-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox2"
                    value="Course 2"
                    v-model="options"
                  />
                  <label
                    class="form-check-label text-white"
                    for="inlineCheckbox2"
                  >
                    G2G Full Yachting package</label
                  >
                </div>
                <div class="form-check py-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox3"
                    value="Course 3"
                    v-model="options"
                  />
                  <label
                    class="form-check-label text-white"
                    for="inlineCheckbox3"
                    >G2G Visa Assistant</label
                  >
                </div>
                <div class="form-check py-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox4"
                    value="Course 4"
                    v-model="options"
                  />
                  <label
                    class="form-check-label text-white"
                    for="inlineCheckbox4"
                    >G2G Marketing Kit</label
                  >
                </div>
              </div>

              <button
                type="button"
                class="form-control btn-main estimate-submit mx-auto"
                @click="sendBookingEmail()"
              >
                <div
                  v-if="loading"
                  class="spinner-border spinner-border-md text-green"
                ></div>
                <span v-if="loading"> <h5 class="pt-2">Sending</h5></span>
                <span v-else
                  ><h5 class="pt-2">{{ title }}</h5></span
                >
                <!-- <h5 className="pt-3 text-uppercase">{{ title }}</h5> -->
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
export default {
  name: "Modal",
  data() {
    return {
      loading: false,
      title: "Send",
      error: "",
      options: [],
      name: "",
      number: "",
      email: "",
    };
  },
  methods: {
    sendBookingEmail() {
      if (this.name === "" || this.email === "") {
        this.error = "Please fill out all fields!";
        setTimeout(() => (this.error = ""), 5000);
      } else {
        axios
          .post(
            "/phpmailer/src/sendInfo.php",

            qs.stringify({
              name: this.name,
              email: this.email,
              number: this.number,
              options: this.options,
              date: this.date,
            })
          )
          .then((response) => {
            this.loading = !false;
            this.title = "Sent :)";
            setTimeout(() => (this.title = "Send"), 5000);
            setTimeout(() => {
              this.loading = !true;
            }, 2000);
            this.name = "";
            this.email = "";
            this.number = "";
            this.options = [];
            console.log(response);
          })
          .catch((error) => {
            if (error) {
              this.title = "Somthing went wrong :(";
            }
            console.log(error);
          });
      }
    },
  },
};
</script>
