<template>
  <div class="home">
    <div class="hero-home">
      <div class="container xlrg-pt">
        <h1
          class="display-1 text-white text-shadow  d-flex flex-column align-items-center align-items-md-start text-center text-md-start"
        >
          The Super Yacht Industry Awaits
        </h1>
        <div class="col-lg-6 pt-5">
          <div class="d-flex justify-content-md-start justify-content-center">
            <router-link
              class="btn btn-main btn-lg mt-5 px-4 me-sm-3 d-flex align-items-center "
              to="/packages"
            >
              <h5 class="pt-2">View Packages</h5>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <section class="container sml-pt sml-pb">
      <h2 class="display-2 text-light-green text-capitalize pb-5">
        Welcome Aspiring Greenies
      </h2>
      <p>
        So, you’ve heard about the super yacht industry and you’re looking for a
        new opportunity to expand your horizons. We have been fortunate enough
        to have worked in the yachting industry for the past 10 years, and we
        would like YOU to experience the same opportunity of adventure filled
        travel, new friendships, financial stability and personal growth with
        the Green to Gold stewardess training programme.
      </p>
      <p>
        Now is the perfect time for you to invest in YOURSELF and escape from
        the crazy Covid world into a life of new opportunities, exploring
        different locations and earning a super-duper salary within the super
        yacht industry.
      </p>
      <div class="row">
        <div class="col-md-6">
          <p>
            Our role is to make this transition into the industry as smooth and
            stress free as possible for you – where we will be right by your
            side every step of the way!
          </p>
          <p>
            We are based between Cape Town and Durban to offer you continuous
            one on one support, stewardess training and guidance on which super
            yacht courses to obtain!
          </p>
          <p>
            Green to Gold? A newcomer in the superyacht industry is known as
            “Green” or a “Greenie”. We are here to instil you with all the
            theoretical knowledge and practical training to take you from Green
            Stewardess to a Gold Stewardess; where we will have your future
            Chief Stew convinced you have been on super yachts for years!
          </p>
        </div>
        <div class="col-md-6">
          <img src="../assets/greetings.jpg" class="img-fluid" alt="" />
        </div>
      </div>
    </section>
    <section class="quote  py-5">
      <div class="container py-5">
        <h2 class="text-shadow fw-normal text-white pb-5">
          Jobs Fill Your Pockets,<br />
          But Adventures Fill Your Soul.
        </h2>
        <h2 class="text-shadow fw-normal text-white pt-5">
          Working On Super Yachts<br />
          Does Both
        </h2>
      </div>
    </section>
    <!-- Cards -->
    <section class="container sml-pt sml-pb home-packages">
      <h2 class="display-2 text-light-green text-capitalize pb-5">
        Green To Gold Packages
      </h2>
      <p>
        Our commitment and service to you does not end once your stewardess
        course is completed. We will be by your side throughout the entire
        process of getting you over to a super yacht hub and onboard a
        superyacht. The vital steps that are required between stewardess
        training and superyacht employment don’t need to be filled with stress
        and uncertainty – allow us to do what we have been doing for years!
      </p>
      <div class="row d-flex row-cols-1 row-cols-lg-4">
        <!-- Card 1 -->
        <div class="col mb-3">
          <div class="card h-100 bg-light-blue" id="card1">
            <img src="../assets/crew.webp" class="card-img-top" alt="..." />
            <div class="card-header">
              <h2 class="fw-light h1 card-title text-center py-5 text-white">
                G2G Stewardess Course
              </h2>
            </div>
            <div class="card-body text-white">
              <div class="flexible">
                <p class="card-text">
                  Specialized Greenie focused stewardess training. A 5-day
                  theoretical and practical stewardess course that offers all
                  the in depth and current training required of you to excel as
                  a super yacht stewardess.
                </p>
              </div>
            </div>
            <div class="d-flex justify-content-center py-5 card-footer">
              <router-link
                to="/packages"
                class="btn btn-main btn-lg px-5 me-sm-3 d-block"
              >
                <h5 class="">Learn More</h5>
              </router-link>
            </div>
          </div>
        </div>

        <!-- Card 2 -->
        <div class="col mb-3">
          <div class="card h-100 bg-light-blue" id="card2">
            <img src="../assets/package4.webp" class="card-img-top" alt="..." />
            <div class="card-header">
              <h2 class="fw-light h1 card-title text-center py-5 text-white">
                G2G Full Yachting package
              </h2>
            </div>
            <div class="card-body text-white">
              <div class="flexible">
                <p class="card-text">
                  All the certification you need to become a super yacht
                  stewardess!
                </p>
                <ul>
                  <li>STCW</li>
                  <li>PDSD</li>
                  <li>ENG1</li>
                  <li>Food and Safety Level II</li>
                  <li>G2G Stewardess Course</li>
                </ul>
              </div>
            </div>
            <div class="d-flex justify-content-center py-5 card-footer">
              <router-link
                to="/packages"
                class="btn btn-main btn-lg px-5 me-sm-3 d-block"
              >
                <h5 class="">Learn More</h5>
              </router-link>
            </div>
          </div>
        </div>

        <!-- Card 3 -->
        <div class="col mb-3">
          <div class="card h-100 bg-light-blue" id="card3">
            <img
              src="../assets/stewardess-course.jpg"
              class="card-img-top w-100"
              alt="..."
            />
            <div class="card-header">
              <h2 class="fw-light h1 card-title text-center py-5 text-white">
                G2G Visa Assistant
              </h2>
            </div>
            <div class="card-body text-white">
              <div class="flexible">
                <p class="card-text">
                  We will arrange either your Schengen visa for Europe OR The
                  B1B2 visa for the USA. We have all the information as well as
                  experience on when, where and how to book your visa to get
                  over to a yachting hub. Leave the complicated stuff up to us!
                </p>
              </div>
            </div>
            <div class="d-flex justify-content-center py-5 card-footer">
              <router-link
                to="/packages"
                class="btn btn-main btn-lg px-5 me-sm-3 d-block"
              >
                <h5 class="">Learn More</h5>
              </router-link>
            </div>
          </div>
        </div>

        <!-- Card 4 -->
        <div class="col mb-3">
          <div class="card h-100 bg-light-blue" id="card3">
            <img
              src="../assets/port.webp"
              class="card-img-top w-100"
              alt="..."
            />
            <div class="card-header">
              <h2 class="fw-light h1 card-title text-center py-5 text-white">
                G2G Marketing Kit
              </h2>
            </div>
            <div class="card-body text-white">
              <div class="flexible">
                <p class="card-text">
                  The super yacht industry is extremely competitive – your CV,
                  cover letter and interview etiquette are of utmost importance.
                  This is your first chance to make a great impression on your
                  future Chief Stew that determines whether you get the job
                  amongst the other 100 greenies applying for the same position.
                </p>
              </div>
            </div>
            <div class="d-flex justify-content-center py-5 card-footer">
              <router-link
                to="/packages"
                class="btn btn-main btn-lg px-5 me-sm-3 d-block"
              >
                <h5 class="">Learn More</h5>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <p class="fw-bold text-center pt-5">
        Not only are you getting certified as a yacht stewardess, but you will
        also receive all the support and guidance you need to make this
        transition into the super yacht industry as stress free as possible.
      </p>
    </section>
    <section class="container sml-pt sml-pb">
      <h2 class="display-2 text-light-green text-capitalize pb-5">
        How Does Green To Gold Land You A Job?
      </h2>
      <div class="row">
        <div class="col-md-6">
          <ul>
            <li>Specialized greenie focused stewardess course</li>
            <li>Personalized superyacht CV design</li>
            <li>Interview preparation sessions with ex Chief Stews</li>
            <li>
              Our close connections with current Captains and Chief Stews in the
              superyacht industry today.
            </li>
            <li>
              Constant one on one support from the day we meet until the day we
              hand you over to your first Chief Stew!
            </li>
          </ul>
          <router-link
            to="/why-g2g"
            class="btn btn-main btn-lg px-5 me-sm-3 mb-4 d-block"
          >
            <h5 class="">Read More</h5>
          </router-link>
        </div>
        <div class="col-md-6">
          <img src="../assets/yacht-crew-job.jpg" class="img-fluid" alt="" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>
