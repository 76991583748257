<template>
  <div class="guide">
    <div class="guide-hero">
      <div class="container xlrg-pt sml-pb">
        <div class="row">
          <div class="col-lg-6 ">
            <h1 class="display-2 text-white text-shadow fw-bold">
              GREEN TO GOLD
            </h1>
            <h1 class="display-2 text-white text-shadow fw-bold">
              GREENIES GUIDE
            </h1>
            <h1 class="display-2 text-white text-shadow fw-bold">
              TO YACHTING
            </h1>
            <h3 class="text-white text-shadow pt-5 sml-pb">
              A FREE ebook to get you started as a Superyacht Stewardess
              Download our FREE Get Started Guide - Green to Gold's ultimate
              guide to becoming a superyacht stewardess
            </h3>
            <form class="w-100 ">
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label text-white"
                  ><h5>Email address</h5></label
                >
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  v-model="email"
                />
                <div id="emailHelp" class="form-text">
                  <p class="text-white">
                    We'll never share your email with anyone else.
                  </p>
                </div>
              </div>
              <div @click="getInContact()">
                <button
                  v-if="email == ''"
                  class="btn btn-main btn-lg mb-4 w-100"
                  disabled
                >
                  <p class="p">Download</p>
                </button>
                <a
                  v-if="email != ''"
                  href="https://dragonwebdesign.co.za/Green to Gold Ebook _compressed.pdf"
                  target="_blank"
                  class="btn btn-main btn-lg mb-4"
                >
                  <p class="p">Download</p>
                </a>
              </div>
            </form>
          </div>
          <div class="col-lg-6">
            <img
              src="../assets/Ebook-index.webp"
              class="img-fluid rounded"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
export default {
  name: "Footer",
  data() {
    return {
      loading: false,
      title: "Send",
      error: "",
      email: "",
    };
  },
  methods: {
    getInContact() {
      if (this.email === "") {
        this.error = "Please fill in your email address!";
        setTimeout(() => (this.error = ""), 5000);
      } else {
        axios
          .post(
            "/phpmailer/src/getInContactg2gdownload.php",

            qs.stringify({
              email: this.email,
            })
          )
          .then((response) => {
            this.loading = !false;
            this.title = "Sent :)";
            setTimeout(() => (this.title = "Send"), 5000);
            setTimeout(() => {
              this.loading = !true;
            }, 2000);
            this.email = "";
            console.log(response);
          })
          .catch((error) => {
            this.title = "Something went wrong";
            console.log(error);
          });
      }
    },
  },
};
</script>
