<template>
  <div class="why">
    <div class="why-hero">
      <div
        class="container xlrg-pt d-flex flex-column align-items-center align-items-md-start text-center text-md-start"
      >
        <h1 class="display-1 text-white text-shadow">
          Why Green to Gold
        </h1>
        <div class="col-lg-6 pt-5">
          <div class="pt-5">
            <router-link
              class="btn btn-main btn-lg px-4 me-sm-3 d-flex align-items-center"
              to="/packages"
            >
              <h5 class="pt-2">View Packages</h5>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <section class="container sml-pt sml-pb">
      <ul>
        <li>
          Our stewardess course classes are small and intimate with a max of 4
          students in order to provide personalised attention to ensure that
          when you arrive on your first superyacht you are filled with
          confidence and familiarity.
        </li>
        <li>
          Support & guidance from the day we meet you until the day we hand you
          over to your future Chief Stew – our relationship with you is
          intimate, focusing on support with:

          <ol>
            <br />
            <li>
              Booking all the relevant superyacht courses you really NEED as a
              yacht stewardess. Getting into the yachting industry is a costly
              process. There specific courses that are required of you as
              stewardesses and other courses that are designed for deckhands.
              For example, as a yacht stewardess you DO NOT need a VHF radio
              certificate. We are here to be authentically honest with you of
              what is required of you as a yacht stewardess and what is not.
            </li>
            <li>
              Assisting you through the visa process. For a first timer this is
              a complicated procedure – but we have all the experience and
              knowledge required to obtain a Schengen or B1B2 visa as a yachtie.
            </li>
            <li>
              Getting you across to a yachting hub safely. We will be by your
              side on WhatsApp, providing guidance from the moment you land in
              Europe or the US to which mode of transport required to arrive
              safely at your crew house along with all the tips in between.
            </li>
            <li>
              Guidance with securing an interview and landing that job with all
              the practise sessions we have done as well as the G2G
              pre-interview confidence booster call.
            </li>
            <li>
              And finally walking onboard oozing with all the confidence and
              familiarity as a stewardess.
            </li>
          </ol>
        </li>
        <li>
          Having left the yacht industry last year – We still have close
          connections to many Captains and Chief Stews. Majority of them post
          privately on their own pages looking for new crew before hitting the
          public yachting pages and agencies. WHY? - They prefer personal
          referrals from their friends, ex colleagues and other networks within
          the industry. - They don’t pay the heavy agency fees. - When posting a
          job for a stewardess on public pages, their inboxes get overrun with
          100’s of CV’s therefore getting referrals from their private accounts
          streamlines the selection process.
        </li>
        <li>
          Advice and support when you’re faced with challenging situations that
          come with working onboard a superyacht. Working onboard yachts can
          have its physical and emotional challenges. You will be required to
          work extremely long hours and be faced with high pressure situations.
          These two combined with working and living amongst 12+ other crew
          members from different backgrounds, cultures and personalities can
          cause conflict situations. When you are faced with any of these
          situations - call on us for advice, we have been there before!
        </li>
      </ul>
    </section>
    <section class="container">
      <h2 class="display-2 text-light-green text-capitalize pb-5">
        To my future aspiring greenies
      </h2>
      <div class="row sml-pb">
        <div class="col-md-6">
          <h3 class="text-dark-blue">
            Training
          </h3>
          <p>
            Over the last 15 years I have been involved in training and managing
            people within the yachting and marketing industry, and over this
            period I have truly developed a passion for it. I strongly believe
            that training should not just be about instructing processes
            procedures, but more on how to operate to the best of your ability
            and exude passion in the process. Creating familiarization and
            consistency is key to a successful career as yacht stewardess. This
            is what’s going to make you stand out amongst the rest and get you
            your Golden Ticket in the industry, AKA your killer Reference
            Letter. We go above and beyond the basic training by providing the
            REAL-LIFE situations on the day-to-day running’s onboard a yacht
            within an interior department to prepare you fully for what’s
            expected of you in the Industry.
          </p>
          <h3 class="text-dark-blue">
            Onboard Support
          </h3>
          <p>
            I have had some of the best years of my life working as a yacht
            stewardess, with the people I have met, the comraderies of the crews
            I have worked with, and the incredible travel destination I have
            visited. But, we all know the saying with the good comes the bad. I
            am here to be honest with you and prepare you for the physical and
            emotional challenges that you will be faced with. When these
            situations arise, call me and I will be so happy to give you all the
            advice you need to keep your mind in a good positive space. This is
            why we keep our classes small so that we can provide personalized
            attention to each of you from your first day of training through to
            your days on board your first superyacht.
          </p>
        </div>
        <div class="col-md-6 onboard-img"></div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Why",
};
</script>
