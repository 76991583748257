import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Why from '../views/Why.vue'
import Course from '../views/Course.vue'
import Packages from '../views/Packages.vue'
import Contact from '../views/Contact.vue'
import Guide from '../views/Guide.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/why-g2g',
    name: 'Why',
    component: Why
  },
  {
    path: '/courses',
    name: 'Course',
    component: Course
  },
  {
    path: '/packages',
    name: 'Packages',
    component: Packages
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/guide',
    name: 'Guide',
    component: Guide
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'link',
  base: process.env.BASE_URL,
  routes, scrollBehavior: function (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {x: 0, y: 0}
    }
  },
})

export default router
