<template>
  <div class="courses">
    <div class="courses-hero">
      <div
        class="container xlrg-pt d-flex flex-column align-items-center justify-content-start align-items-md-start text-center text-md-start"
      >
        <h1 class="display-1 text-white text-shadow">
          Green to Gold Stewardess Super Yacht Course
        </h1>
        <div class="col-lg-6 pt-5">
          <div class="pt-5">
            <router-link
              class="btn btn-main btn-main-mobile-lg btn-lg px-4 me-sm-3 d-flex align-items-center"
              to="/packages"
            >
              <h5 class="pt-2">View Packages</h5>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="modules">
      <div class="module">
        <div class="bg-white">
          <section class="container sml-pt sml-pb">
            <h2
              class="display-3 text-center text-light-green text-capitalize pb-2"
            >
              Introduction To Yachting
            </h2>
            <div class="row">
              <div class="col-md-6"></div>
              <div class="col-md-6"></div>
            </div>
            <h3 class="text-dark-blue">
              Module One
            </h3>
            <ul>
              <li>
                How to successfully plan for your yachting career - covering
                budget and what super yacht courses are required.
              </li>
              <li>
                The different types of visa’s, how to get visas without boat
                papers, which visa to get and more in-depth information on
                obtaining a visa.
              </li>
              <li>Salaries and career growth within the industry.</li>
              <li>Bank accounts, saving plans and taxes</li>
              <li>
                Future stewardess super yacht courses that your Captain pays
                for.
              </li>
              <li>
                Covid-19 and what the future of the yachting industry holds.
              </li>
              <li>
                The Mediterranean and The Caribbean – there is a golden window
                of when to enter both seasons!
              </li>
              <li>Motor Yachts vs Sail Yacht’s.</li>
              <li>Private vs Charter.</li>
              <li>Global marina’s.</li>
              <li>
                Yacht layout familiarization and the differences between a 30m
                yacht and a 90m yacht.
              </li>
              <li>Yacht terminology</li>
              <li>Crew hierarchy – who does what and who do you report to?</li>
            </ul>
          </section>
          <div class="module1-img"></div>
        </div>
      </div>
      <div class="module">
        <div class="bg-white">
          <section class="container sml-pt sml-pb">
            <h2 class="display-3 text-light-green text-capitalize pb-2">
              Arriving At A Yachting Hub
            </h2>
            <h3 class="text-dark-blue">
              Module Two
            </h3>
            <ul>
              <li>
                Daily budget – how to live off the Euro/Dollar.
              </li>
              <li>
                Crew houses explained – recommended crew houses, crew house to
                do’s and don’ts and some extremely important top tips for while
                you’re based at a crew house.
              </li>
              <li>Networking hubs.</li>
              <li>
                Dock walking – lists of marinas, dock walking to do’s and don’ts
                as well as other top tips.
              </li>
              <li>
                Daywork – top tips on how to turn day work into a permanent
                position.
              </li>
              <li>
                Crew agents – a pre-compiled list of all the agents. How to set
                up meetings with them and how to set up your online profiles.
                PLUS all the online application do’s and don’ts to get you ahead
                of the rest!.
              </li>
              <li>
                Interviews – the procedure, what to say, body language,
                confidence, how to win over your future Chief Stew/Captain! PLUS
                a pre interview CONFIDENCE BOOSTER call from me.
              </li>
            </ul>
          </section>
          <div class="module2-img"></div>
        </div>
      </div>
      <div class="module">
        <div class="bg-white">
          <section class="container sml-pt sml-pb">
            <h2 class="display-3 text-light-green text-capitalize pb-2">
              Life Onboard A Superyacht
            </h2>
            <h3 class="text-dark-blue">
              Module Three
            </h3>
            <ul>
              <li>
                Your very first day onboard a super yacht and how it plays out.
              </li>
              <li>
                How to live amongst 8+ other crew mates mindfully: crew mess
                etiquette, cabin share etiquette and general guidelines for
                living in extremely close quarters with crew members.
              </li>
              <li>
                The interior department explained – the interior department will
                be structured differently depending on the size of the yacht.
              </li>
              <li>
                Your role as a junior stewardess explained – duties,
                understanding the importance of hierarchy and your position and
                what’s expected of you as a junior.
              </li>
              <li>
                A typical day on charter (guests on) within housekeeping,
                service and laundry.
              </li>
              <li>
                A typical day off charter (guests off) within housekeeping,
                service and laundry.
              </li>
            </ul>
          </section>
          <div class="module3-img"></div>
        </div>
      </div>
      <div class="module">
        <div class="bg-white">
          <section class="container sml-pt sml-pb">
            <h2 class="display-3 text-light-green text-capitalize pb-2">
              Housekeeping
            </h2>
            <h3 class="text-dark-blue">
              Module Four
            </h3>
            <ul>
              <li>
                The difference between turns ups, turn downs and detailing.
              </li>
              <li>
                US/UE product familiarization – what to use where and what NOT
                to use where.
              </li>
              <li>
                Time management – the extreme importance of managing your time
                between housekeeping & laundry and how to perfect it.
              </li>
              <li>
                Perfecting your bed making skills along with all the top tips.
              </li>
              <li>
                Folding techniques of towels, toilet paper and tissues.
              </li>
              <li>
                Wardrobe arrangement.
              </li>
              <li>
                Unpacking/packing for guests.
              </li>
              <li>
                Developing an eye for detail – where to focus your attention.
              </li>
            </ul>
          </section>
          <div class="module4-img"></div>
        </div>
      </div>
      <div class="module">
        <div class="bg-white ">
          <section class="container sml-pt sml-pb">
            <h2 class="display-3 text-light-green text-capitalize pb-2">
              Service
            </h2>
            <h3 class="text-dark-blue">
              Module Five
            </h3>
            <ul>
              <li>
                A typical day on service with guests onboard from breakfast
                service through to lunch and dinner service.
              </li>
              <li>
                Different styles of service and their procedures – American,
                Russian, Silver and Buffet.
              </li>
              <li>
                Professional service and etiquette of wine.
              </li>
              <li>
                Champagne
              </li>
              <li>
                Napkin folding.
              </li>
              <li>
                Styles of coffee.
              </li>
              <li>
                Cocktail/Canapes hour.
              </li>
              <li>
                Cocktails and cocktail garnish training.
              </li>
              <li>
                Communication with the chef during service.
              </li>
              <li>
                Pulling for service.
              </li>
              <li>
                Guest preference lists.
              </li>
              <li>
                Pulling for service.
              </li>
            </ul>
          </section>
          <div class="module5-img"></div>
        </div>
      </div>
      <div class="module">
        <div class="bg-white">
          <section class="container sml-pt sml-pb">
            <h2 class="display-3 text-light-green text-capitalize pb-2">
              Laundry
            </h2>
            <h3 class="text-dark-blue">
              Module Six
            </h3>
            <ul>
              <li>
                A typical day in laundry with guests off and guests on.
              </li>
              <li>
                US/UE laundry product familiarisation.
              </li>
              <li>
                Stain treatment guidelines.
              </li>
              <li>
                Laundry machinery function options and use.
              </li>
              <li>
                Familiarizing yourself with a daily laundry system/procedure.
              </li>
              <li>
                Understanding clothes labels and how to handle delicate clothing
                items.
              </li>
              <li>
                What to iron and what not to iron.
              </li>
              <li>
                How to manage time between housekeeping and laundry.
              </li>
              <li>
                Folding techniques of guest/crew linen & clothes.
              </li>
              <li>
                How to iron and steam various items and what NOT to iron but to
                rather steam.
              </li>
              <li>
                How to starch table linen.
              </li>
            </ul>
          </section>
          <div class="module6-img"></div>
        </div>
      </div>
      <div class="module">
        <div class="bg-white">
          <section class="container sml-pt sml-pb">
            <h2 class="display-3 text-light-green text-capitalize pb-2">
              Checklists
            </h2>
            <h3 class="text-dark-blue">
              Module Seven
            </h3>
            <ul>
              <li>
                Early, mids and late stewardess checklists.
              </li>
              <li>
                Turn up and turn down checklists.
              </li>
              <li>
                Detailing checklists.
              </li>
              <li>
                Laundry procedure checklists.
              </li>
              <li>
                Beach set up checklists
              </li>
              <li>
                Pulling for meals checklists and so much more so that you can
                start familiarizing yourself with how things are done onboard
                different sized yachts.
              </li>
            </ul>
          </section>
          <div class="module7-img"></div>
        </div>
      </div>
      <div class="module">
        <div class="bg-white">
          <section class="container sml-pt sml-pb">
            <h2 class="display-3 text-light-green text-capitalize pb-2">
              Communication
            </h2>
            <h3 class="text-dark-blue">
              Module Eight
            </h3>
            <ul>
              <li>
                Radio communication - how to keep open and clear communication
                with your interior department, the chef and exterior department.
              </li>
              <li>
                How to communicate professionally with guests within service and
                housekeeping.
              </li>
              <li>
                The vital guidance of verbal and nonverbal communication with
                the rest of your crew.
              </li>
            </ul>
          </section>
          <div class="module8-img"></div>
        </div>
      </div>
      <div class="module">
        <div class="bg-white">
          <section class="container sml-pt sml-pb">
            <h2 class="display-3 text-light-green text-capitalize pb-2">
              Survive & Thrive
            </h2>
            <h3 class="text-dark-blue">
              Module Nine
            </h3>
            <ul>
              <li>
                The not so fun part of yachting: how to handle conflict on
                board, peer pressure, how to handle criticism and how to keep
                your career a good positive one!
              </li>
              <li>
                Self-care: recommended routine during your break time, the art
                of a cat nap, recommended stretches in the confined space of
                your cabin, and plenty other tips to keep you happy, healthy and
                to avoid burnout!
              </li>
            </ul>
          </section>
          <div class="module9-img"></div>
        </div>
      </div>
      <div class="module">
        <div class="bg-white">
          <section class="container sml-pt sml-pb">
            <h2 class="display-3 text-light-green text-capitalize pb-2">
              Finances and Career Plan
            </h2>
            <h3 class="text-dark-blue">
              Module Ten
            </h3>
            <ul>
              <li>
                One of the reasons you got into yachting is to make money and
                there is a good chance that you have never experienced this
                amount of disposable income ever in your life.
              </li>
              <li>
                We chat about learning to manage the abundance of cash you will
                be faced with. how to live at this elevated income tier and how
                to make it work for you long term.
              </li>
              <li>
                Career growth as a yacht stewardess onboard different sized
                yachts.
              </li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>
