<template>
  <div class="packages">
    <div class="package-hero">
      <div
        class="container xlrg-pt d-flex flex-column align-items-center align-items-md-start text-center text-md-start"
      >
        <h1 class="display-1 text-white text-shadow">
          Green to Gold Packages
        </h1>
        <div class="col-lg-6 pt-5">
          <div class="pt-5">
            <router-link
              class="btn btn-main btn-lg px-4 me-sm-3 d-flex align-items-center"
              to="/courses"
            >
              <h5 class="pt-2">View Course Content</h5>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <section class="container my-5 rounded pt-2">
      <div class="row">
        <button
          v-on:click="p = '1'"
          v-if="p == '1'"
          class=" btn col-3 product-page-btn"
          :class="selectedBg"
        >
          <h2 class="fw-light h4 text-center py-5" :class="selectedText">
            G2G Stewardess Course
          </h2>
        </button>

        <button
          v-on:click="p = '1'"
          class=" btn col-3 product-page-btn"
          :class="lightBg"
          v-else
        >
          <h2 class="fw-light h4 text-center py-5" :class="darkText">
            G2G Stewardess Course
          </h2>
        </button>

        <button
          v-on:click="p = '2'"
          v-if="p == '2'"
          class="btn col-3 product-page-btn"
          :class="selectedBg"
        >
          <h2 class="fw-light h4 text-center py-5" :class="selectedText">
            G2G Full Yachting package
          </h2>
        </button>

        <button
          v-on:click="p = '2'"
          v-else
          class="btn col-3 product-page-btn"
          :class="lightBg"
        >
          <h2 class="fw-light h4 text-center py-5" :class="darkText">
            G2G Full Yachting package
          </h2>
        </button>

        <button
          v-on:click="p = '3'"
          v-if="p == '3'"
          class="btn col-3 product-page-btn"
          :class="selectedBg"
        >
          <h2 class="fw-light h4 text-center py-5" :class="selectedText">
            G2G Visa Assistant
          </h2>
        </button>

        <button
          v-on:click="p = '3'"
          v-else
          class="btn col-3 product-page-btn"
          :class="lightBg"
        >
          <h2 class="fw-light h4 text-center py-5" :class="darkText">
            G2G Visa Assistant
          </h2>
        </button>

        <button
          v-on:click="p = '4'"
          v-if="p == '4'"
          class="btn col-3 product-page-btn"
          :class="selectedBg"
        >
          <h2 class="fw-light h4 text-center py-5" :class="selectedText">
            G2G Marketing Kit
          </h2>
        </button>

        <button
          v-on:click="p = '4'"
          v-else
          class="btn col-3 product-page-btn"
          :class="lightBg"
        >
          <h2 class="fw-light h4 text-center py-5" :class="darkText">
            G2G Marketing Kit
          </h2>
        </button>
      </div>

      <div class=" btn bg-light-blue row">
        <div class="text-white" v-if="p == 1">
          <transition
            enter-active-class="animate__animated animate__zoomIn"
            leave-active-class="animate__animated animate__fadeOut"
            mode="out-in"
          >
            <PackageOne></PackageOne>
          </transition>
        </div>
        <div class="text-white" v-if="p == 2">
          <transition
            enter-active-class="animate__animated animate__zoomIn"
            leave-active-class="animate__animated animate__fadeOut"
            mode="out-in"
          >
            <PackageTwo></PackageTwo>
          </transition>
        </div>
        <div class="text-white" v-if="p == 3">
          <transition
            enter-active-class="animate__animated animate__zoomIn"
            leave-active-class="animate__animated animate__fadeOut"
            mode="out-in"
          >
            <PackageThree></PackageThree>
          </transition>
        </div>
        <div class="text-white" v-if="p == 4">
          <transition
            enter-active-class="animate__animated animate__zoomIn"
            leave-active-class="animate__animated animate__fadeOut"
            mode="out-in"
          >
            <PackageFour></PackageFour>
          </transition>
        </div>
      </div>
    </section>
    <!-- <section class="container sml-pt sml-pb">
      <div class="row d-flex card-group">
        <div class="card bg-light-blue" id="card1">
          <img
            src="../assets/stewardess-course.jpg"
            class="card-img-top"
            alt="..."
          />
          <div class="card-body text-white">
            <h2 class="fw-light h1 card-title text-center pt-5">
              G2G Yacht Stewardess Course
            </h2>
            <div class="flexible">
              <h5 class="text-center pb-5">Package One</h5>
              <p>
                We will facilitate a 5-day theoretical and practical Stewardess
                Training Programme.
              </p>
              <ul>
                <li>
                  The course comprises of 300 pages of theoretical information
                  that you can refer back to and use throughout your yachting
                  career. The practical applications required of you to excel as
                  a yacht stewardess - from focusing on the professional service
                  and etiquette of wine to familiarizing yourself with yacht
                  laundry procedures to the in-depth training within the 5+ star
                  standards of working onboard a superyacht.
                </li>
                <li>Certificate of Completion.</li>
                <li>Course coverage letter to your future Chief Stew.</li>
              </ul>
            </div>
          </div>
          <div class="d-flex justify-content-center py-5 card-footer">
            <button
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              class="btn btn-main btn-lg px-5 me-sm-3 d-block"
            >
              <h5 class="pt-2">Cost: R 6 500.00</h5>
            </button>
          </div>
        </div>

        <div class="card bg-light-blue" id="card2">
          <img src="../assets/port.webp" class="card-img-top" alt="..." />
          <div class="card-body text-white">
            <h2 class="h1 card-title text-center pt-5 fw-light">
              Your Personal G2G Admin Assistant
            </h2>
            <div class="flexible">
              <h5 class="text-center pb-5">Package Two</h5>
              <p>
                We will assist with the following:
              </p>
              <ul>
                <li>
                  Booking all the relevant courses that are required of you to
                  enter the industry as a Yacht Stewardess.
                </li>
                <li>
                  Constant guidance and one on one support throughout the entire
                  visa process. Probably one of the biggest and most complicated
                  procedures, but this is a process we are extremely familiar
                  with and know all the in’s and out’s to!
                </li>
                <li>
                  Assistance with getting you over to your first yachting hub!
                  We will provide support with booking your flight and crew
                  house.
                </li>
              </ul>
            </div>
          </div>
          <div class="d-flex justify-content-center py-5 card-footer">
            <button
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              class="btn btn-main btn-lg px-5 me-sm-3 d-block"
            >
              <h5 class="pt-2">Cost: R 2 500.00</h5>
            </button>
          </div>
        </div>

        <div class="card bg-light-blue" id="card3">
          <img src="../assets/crew.webp" class="card-img-top w-100" alt="..." />
          <div class="card-body text-white">
            <h2 class="fw-light h1 card-title text-center pt-5">
              Your Personal G2G Marketing Coach
            </h2>
            <div class="flexible">
              <h5 class="text-center pb-5">Package Three</h5>
              <p>
                Marketing yourself in the best way is what’s going to get your
                foot in the door as a yacht stewardess. As an Ex Chief Stew, I
                know exactly what your CV should look like and contain not only
                to get your CV into the top of the pile of 100’s of greenie CV’s
                but also to catch any Chief Stew’s attention.
              </p>
              <ul>
                <li>
                  G2G Marketing Kit – Super Yacht Stewardess designed CV, Cover
                  Letter and Business cards.
                </li>
                <li>
                  Interview prep sessions to get your confidence up and to
                  prepare you for all the questions and answers that would be
                  posed by your future Chief Stew.
                </li>
              </ul>
            </div>
          </div>
          <div class="d-flex justify-content-center py-5 card-footer">
            <button
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              class="btn btn-main btn-lg px-5 me-sm-3 d-block"
            >
              <h5 class="pt-2">Cost: R 800.00</h5>
            </button>
          </div>
        </div>
        <p class="fw-bold text-center pt-5">
          Not only are you getting certified as a yacht stewardess, but you will
          also receive all the support and guidance you need to make this
          transition into the super yacht industry as stress free as possible.
        </p>
      </div>
    </section> -->
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      p: "1",
      selectedBg: " bg-light-blue",
      selectedText: " text-white",
      lightBg: " bg-light",
      darkText: " text-dark",
    };
  },
};
</script>
