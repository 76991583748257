import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'animate.css'

Vue.config.productionTip = false
Vue.component(
  "Header",
  require("./components/Header.vue").default
);
Vue.component(
  "Footer",
  require("./components/Footer.vue").default
);
Vue.component(
  "Modal",
  require("./components/Modal.vue").default
);
Vue.component(
  "PackageOne",
  require("./components/PackageOne.vue").default
);
Vue.component(
  "PackageTwo",
  require("./components/PackageTwo.vue").default
);
Vue.component(
  "PackageThree",
  require("./components/PackageThree.vue").default
);
Vue.component(
  "PackageFour",
  require("./components/PackageFour.vue").default
);
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
